<template>
  <section>
    <!-- 个人中心 -->
    <div class="tool">
      <span>欢迎您,{{ name }}</span>
      <el-dropdown @command="handlerCommand">
        <span class="el-dropdown-link">
          <img :src="src" alt class="avatar bm"/>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
          <el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 工具栏 -->
    <!-- <div class="tool bar">
      <div title="消息">
        <i class="iconfont icon-remind bm"></i>
        <span class="num" v-if="noticeNum">{{noticeNum}}</span>
      </div>
    </div> -->
    <change-password v-if="changePasswordVisible" ref="changePassword"></change-password>
  </section>
</template>

<script>
import src from '../../../src/assets/images/avatar.png'
import ChangePassword from "../../views/password/changePassword";

export default {
  name: 'toolbar',
  components: {ChangePassword},
  data() {
    return {
      src: src,
      changePasswordVisible: false
    }
  },
  props: {
    name: {
      type: String
    }
  },

  computed: {
    noticeNum() {
      return 15
    }
  },

  methods: {
    handlerCommand(command) {
      if (command === 'logOut') {
        this.$store.dispatch('user/logOut').then(() => {
          this.$router.push({path: '/login'})
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      } else if (command === 'changePwd') {
        this.changePasswordVisible = true
        this.$nextTick(() => {
          this.$refs["changePassword"].init()
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 14px;

section {
  display: inline-block;
  position: relative;

  .bar {
    margin-right: 15px;

    div {
      position: relative;
      height: 60px;
      cursor: pointer;

      // 圆点
      .num {
        position: absolute;
        min-width: $r;
        height: $r;
        line-height: $r;
        border-radius: 50%;
        background-color: #f56c6c;
        top: 16px;
        right: -4px;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .tool {
    float: right;
    height: 60px;
    line-height: 60px;
    position: relative;

    .el-dropdown {
      color: #fff;
      margin-left: 15px;
    }

    .avatar {
      width: 36px;
      height: 36px;
    }

    .iconfont {
      font-size: 24px;
    }
  }
}
</style>
