<template>
  <section style="height: 100%;">
    <transition name="fade-transform" mode="out-in">
      <!-- keep-alive   活性-->
       <keep-alive>
      <router-view :key="key"></router-view> <!--这个router-view用来在主面板渲染嵌套路由，即router里的children路由-->
       </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'mainView',
  computed: {
    key () {
      return this.$route.path
    },

  }
}
</script>

<style lang="scss" scoped>
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-18px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(18px);
}
</style>
