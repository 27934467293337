// 获取,设置,删除cookie
import Cookie from 'js-cookie'

// token的键名
const TokenKey = 'sbgl-Token'

export function setToken (token) {
  Cookie.set('token', token)
  Cookie.set(TokenKey, token)
}

export function getToken () {
  return Cookie.get(TokenKey)
}

export function removeToken () {
  Cookie.remove('token')
  Cookie.remove(TokenKey)
}

export function setUserName(username) {
  Cookie.set('username', username)
}

export function getUserName() {
  return Cookie.get('username')
}

export function removeUserName() {
  Cookie.remove('username')
}

export function setUserId(userId) {
  Cookie.set("userid", userId)
}

export function getUserId() {
  return Cookie.get('userid')
}

export function removeUserId() {
  Cookie.remove('userid')
}


export function setUserTel(tel) {
  Cookie.set("tel", tel)
}

export function getUserTel() {
  return Cookie.get("tel")
}

export function removeUserTel() {
  Cookie.remove("tel")
}

export function setUserType(type) {
  Cookie.set("type", type)
}

export function getUserType() {
  return Cookie.get("type")
}

export function removeUserType() {
  Cookie.remove("type")
}
