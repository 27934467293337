/**
 * 设置浏览器标题
 * @param title
 * @returns {string|*}
 */
export function setTitle (title) {
  if (title) {
    return title
  } else {
    return '设备管理平台'
  }
}
