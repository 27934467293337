<template>
  <el-dialog title="修改密码" :close-on-click-modal="false" :visible.sync="dialogVisible">
    <el-form :model="dataForm" :rules="dataRule" ref="passwordForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="dataForm.password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passwordRepeat">
        <el-input type="password" v-model="dataForm.passwordRepeat" placeholder="请再次输入密码以确认" show-password></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dataFormSubmit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getUserId, getUserTel} from "../../utils/cookie";
import {accountUpdatePassword} from "../../api/account";

export default {
  name: 'changePassword',
  data() {
    let passwordRepeatValidater = (rule, value, callback) => {
      if (value !== this.dataForm.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      dataForm: {
        id: '',
        tel: '',
        password: '',
        passwordRepeat: ''
      },
      dataRule: {
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        passwordRepeat: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {validator: passwordRepeatValidater, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(){
      this.dialogVisible = true
      this.$nextTick(() => {
        this.dataForm.id = getUserId()
        this.dataForm.tel = getUserTel()
      })

    },
    dataFormSubmit() {
      this.$refs["passwordForm"].validate((valid) => {
        if (valid) {
          accountUpdatePassword(this.dataForm.id, this.dataForm.tel, this.dataForm.password).then((res) => {
            if (res && res.code === 1) {
              this.$message({
                message: '修改密码成功!请重新登录',
                type: 'success',
                duration: 2000,
                onClose: () => { //重新登录
                  this.$store.dispatch("user/logOut").then((res) => {
                    this.$router.replace("/login")
                  })
                }
              })
              this.dialogVisible = false
            } else {
              this.$message.error(res.data[0])
            }
          })
        } else {
          this.$message.error("修改失败!")
          return false;
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
