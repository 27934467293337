<template>
  <div class="index">
    <el-container>
      <!--头部-->
      <el-header>
        <div class="header-left">
          <h1>校贝通设备管理平台</h1>
        </div>

        <!--右边的个人中心-->
        <div class="header-right">
          <toolbar :name="name"></toolbar>
        </div>
      </el-header>

      <el-container class="main">
        <!-- 导航栏 -->
        <el-aside style="width: 210px" :class="[isCollapse?'hideSide' : '']">
          <side :isCollapse="isCollapse"></side>
        </el-aside>

        <!--主体-->
        <el-scrollbar class="main">
          <el-main>
            <!--<breadcrumb></breadcrumb>-->
            <main-view></main-view>
          </el-main>
        </el-scrollbar>
      </el-container>

    </el-container>
  </div>
</template>

<script>

import Side from '../../components/layout/side'
import Toolbar from '../../components/layout/toolbar'
import MainView from '../../components/layout/mainView'
import Breadcrumb from '../../components/layout/breadcrumb'
import Cookie from "js-cookie";

export default {
  name: 'layout',
  components: {Toolbar, Side, MainView, Breadcrumb},
  data() {
    return {
      screenWidth: 0 // 窗口宽度
    }
  },
  computed: {
    // ...mapGetters(["token", "name"]),

    token() {
      return this.$store.getters['user/token']
    },
    name() {
      // return this.$store.getters['user/username'] vuex的用户名刷新会丢失
      return Cookie.get('username')
    },
    // 控制侧边导航栏折叠
    isCollapse() {
      return this.screenWidth < 850
    },

  },
  mounted() {
    //监听页面尺寸改变
    window.addEventListener('resize', e => {
      this.screenWidth = e.currentTarget.innerWidth
    })
  },
  beforeMount() {
    this.screenWidth = window.innerWidth
  }
}
</script>

<style lang="scss" scoped>
$color: #3da9fc;
$textColor: #fff;
// $padTop: 60px; //el-main padding-top值

.index {
  width: 100%;
  height: 100%;

  // 头部
  .el-header {
    background-color: $color;
    color: $textColor;
    line-height: 60px;

    // 头部左
    .header-left {
      float: left;
      height: 100%;
    }

    // 头部右
    .header-right {
      float: right;
      height: 100%;
    }
  }

  .main.el-scrollbar {
    flex: 1;
    height: calc(100vh - 60px);
    background-color: #f0f2f5;
    // 主体
    .el-main {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
  }
}

.main {
  height: calc(100% - 60px);
}

// // 侧边导航栏动画
.el-aside {
  transition: width 0.2s;
}

.el-aside.hideSide {
  width: 0px !important;
}
</style>
