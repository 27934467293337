import request from '../utils/request'

/**
 * 用户登录
 * @param tel 电话号码
 * @param password 密码
 * @returns {AxiosPromise}
 */
export function login (tel, password) {
  return request({
    url: '/login',
    method: 'post',
    data: {
      tel, password
    }
  })
}

// export function getUserInfo (token) {
//   return request({
//     url: '/getUserInfo',
//     method: 'post',
//     data: {
//       token
//     }
//   })
// }

/**
 * 用户退出登录
 * @param token
 * @returns {AxiosPromise}
 */
export function logOut (token) {
  return request({
    url: '/logout',
    method: 'get',
    params: {
      token
    }
  })
}
