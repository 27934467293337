import request from "../utils/request";
import qs from "qs";

/**
 * 分页查询账号
 * @param pageNumber
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function accountQueryByPage(pageNumber, pageSize) {
  return request({
    url: '/account/queryByPage',
    method: 'get',
    params: {
      pageNumber, pageSize
    }
  })
}

/**
 * 根据id删除账号
 * @param id 待删除账号id
 * @returns {AxiosPromise}
 */
export function accountDeleteById(id) {
  return request({
    url: '/account/deleteById',
    method: 'DELETE',
    params: {
      id
    },
    // paramsSerializer: (params) => {
    //   return qs.stringify(params)
    // }
  })
}

/**
 * 新增账号
 * @param tel
 * @param password
 * @param type
 * @param username
 * @returns {AxiosPromise}
 */
export function accountInsert(tel, password, type, username) {
  return request({
    url: '/account/insert',
    method: 'post',
    data: {
      tel, password, type, username
    }
  })
}

/**
 * 账号编辑信息
 * @param id
 * @param tel
 * @param type
 * @param username
 * @returns {AxiosPromise}
 */
export function accountUpdate(id, tel, type, username) {
  return request({
    url: '/account/update',
    method: 'post',
    data: {
      id, tel, type, username
    }
  })
}

/**
 * 修改密码
 * @param id
 * @param tel
 * @param password
 * @returns {AxiosPromise}
 */
export function accountUpdatePassword(id, tel, password) {
  return request({
    url: '/account/updatePassword',
    method: 'post',
    data: {
      id, tel, password
    }
  })
}
