<template>
  <el-breadcrumb>
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(route, index) in list" :key="route.path">
        <!--不存在重定向或者索引位于list末尾时显示span-->
        <span v-if="route.redirect == '' || index == list.length-1">{{route.meta.title}}</span>
        <a v-else @click.prevent="toLink(route)">{{route.meta.title}}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { compile } from 'path-to-regexp'

export default {
  name: 'breadcrumb',
  data () {
    return {
      list: [] // 面包屑列表
    }
  },
  watch: {
    $route () {
      this.setBreadcrumb()
    }
  },
  created: function () {
    this.setBreadcrumb()
  },
  methods: {
    // 设置面包屑
    setBreadcrumb () {
      // matched:route对象的所有信息
      // 过滤出含有mate及mate.title
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      )

      // if (!this.isHome(matched[0])) {
      //   matched = [{ path: '/home', meta: { title: '首页' } }].concat(matched)
      // }

      this.list = matched.filter(item => item.meta && item.meta.title)
    },

    // 判断是否为首页
    isHome (route) {
      if (!route.name) {
        return false
      }
      return route.name == '首页'
    },

    // 点击面包屑进行路由跳转
    toLink (route) {
      const { redirect, path } = route

      const { params } = this.$route

      if (redirect) {
        return this.$router.push(redirect,onComplete=>{}, onAbort=>{}) //加上两个钩子避免重复点击面包屑报错
      }

      // 地址匹配
      const toPath = compile(path)

      this.$router.push(toPath(params),onComplete=>{}, onAbort=>{}) //加上两个钩子避免重复点击面包屑报错
    }
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  line-height: normal;
  display: inline-block;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}
</style>
