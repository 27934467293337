import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../views/layout/layout'
import {setTitle} from '../utils/utils'
import {getToken} from '../utils/cookie'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  // 登录页
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/login/login'),
    meta: {
      onSide: false // 自定义属性，侧边栏渲染时不渲染该路由
    },

  },
  // 主页
  {
    path: '/',
    redirect: '/partner', // 重定向到合作方
    name: '主页',
    // component: Layout,
    meta: {
      title: '首页',
      onSide: false // 侧边栏渲染时是否显示该路由
    },
    /*children: [{
      path: 'home',
      name: '首页',
      component: () => import('../views/home/Home'),
      meta: {
        title: '首页',
        icon: 'icon-shouye1',
        noChildren: true
      }
    }]*/

  },

  //修改密码
  // {
  //   path: '/password',
  //   component: Layout,
  //   redirect: '/password/changePwd', // 重定向到children
  //   meta: {
  //     onSide: true
  //   },
  //   children: [{
  //     path: '/changePwd',
  //     name: '修改密码',
  //     component: () => import('../views/password/changePassword'),
  //     meta: {
  //       title: '修改密码'
  //     }
  //   }]
  // },

  // 合作方
  {
    path: '/partner',
    redirect: '/partner/partner',
    component: Layout,
    name: '合作方',
    meta: {
      onSide: true
    },
    children: [{
      path: "partner",
      name: '合作方',
      component: () => import('../views/partner/Partner'),
      meta: {
        title: '合作方',
        icon: 'icon-shouye1',
        noChildren: true
      }
    }]
  },
  //申请审核
  {
    path: '/applyReview',
    redirect: '/applyReview/review',
    component: Layout,
    name: '申请审核',
    meta: {
      onSide: true
    },
    children: [{
      path: 'review',
      name: '申请审核',
      component: () => import('../views/applyReview/ApplyReview'),
      meta: {
        title: '申请审核',
        icon: 'icon-shouye1',
        noChildren: true
      }
    }]
  },
  //账号管理
  {
    path: '/account',
    redirect: '/account/account',
    component: Layout,
    name: '账号管理',
    meta: {
      onSide: true
    },
    children: [{
      path: 'account',
      name: '账号管理',
      component: () => import('../views/account/Account'),
      meta: {
        title: '账号管理',
        icon: 'icon-shouye1',
        noChildren: true
      }
    }]
  },

  // 设备管理
  // {
  //   path: '/test',
  //   redirect: '/test/account',
  //   component: Layout,
  //   name: '设备管理相关',
  //   meta: {
  //     title: '设备管理相关(一级表单)',
  //     icon: 'icon-yuangongkaopingjiandingbiao',
  //     // roles: ['admin', 'audit', 'appraisal']
  //     onSide: true
  //   },
  //   children: [
  //     {
  //       path: 'apply-review',
  //       name: '申请审核',
  //       component: () => import('../views/applyReview/ApplyReview'),
  //       meta: {
  //         title: '申请审核',
  //         // roles: ['audit']
  //         onSide: true
  //       }
  //     },
  //     {
  //       path: 'partner',
  //       name: '合作方',
  //       component: () => import('../views/partner/Partner'),
  //       meta: {
  //         title: '合作方',
  //         // roles: ['appraisal']
  //         onSide: true
  //       }
  //     },
  //   ]
  // },

  // 404
  {
    path: '*',
    redirect: '/404',
    meta: {
      onSide: false
    },
    component: () => import('../views/404/404')
  }
]


// 无需权限的路由
// export const commonRoutes = [
//   // 登录页
//   {
//     path: '/login',
//     name: '登录',
//     meta: {
//       onSide: false // 自定义属性，渲染时不显示侧边栏
//     },
//     component: () => import('../views/login/login')
//   },
//   // 主页
//   {
//     path: '/',
//     redirect: '/home', // 重定向到children
//     component: Layout,
//     children: [{
//       path: '/home',
//       name: '首页',
//       component: () => import('../views/home/Home'),
//       meta: {
//         title: '首页',
//         icon: 'icon-shouye1',
//         noChildren: true
//       }
//     }]
//
//   },
//
//   //修改密码
//   {
//     path: '/password',
//     component: Layout,
//     redirect: '/password/changePwd', // 重定向到children
//     meta: {
//       onSide: false
//     },
//     children: [{
//       path: '/changePwd',
//       name: '修改密码',
//       component: () => import('../views/password/changePassword'),
//       meta: {
//         title: '修改密码'
//       }
//     }]
//   }
// ]

// 需要权限的路由，异步加载
// export const permitRoutes = [
//
//   // 404
//   {
//     path: '*',
//     redirect: '/404',
//     meta: {
//       onSide: false
//     },
//     component: () => import('../views/404/404')
//   },
//
// // 考评记录
// {
//   path: '/records',
//       component: Layout,
//     redirect: '/records/school-evalution',
//     name: '考评记录',
//     meta: {
//   title: '考评记录',
//       icon: 'icon-yuangongkaopingjiandingbiao',
//       roles: ['admin', 'audit', 'appraisal']
// },
//   children: [{
//     path: 'school-evalution',
//     name: '全校考评记录',
//     component: () => import('../views/records/schoolEvalution.vue'),
//     meta: {
//       title: '全校考评记录',
//       roles: ['admin']
//     }
//   },
//     {
//       path: 'my-examine',
//       name: '我的审核记录',
//       component: () => import('../views/records/myExamine.vue'),
//       meta: {
//         title: '我的审核记录',
//         roles: ['audit']
//       }
//     },
//     {
//       path: 'my-evalution',
//       name: '我的考评记录',
//       component: () => import('../views/records/myEvalution.vue'),
//       meta: {
//         title: '我的考评记录',
//         roles: ['appraisal']
//       }
//     },
//     {
//       path: 'class-evalution',
//       name: '本班考评记录',
//       component: () => import('../views/records/classEvalution.vue'),
//       meta: {
//         title: '本班考评记录',
//         roles: ['bzr']
//       }
//     }
//   ]
// },
// ]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})

// const router = createRouter()


// 重置路由
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher
// }

const whiteRouterList = ['/login', '/password'] // 白名单路由
// 导航守卫
router.beforeEach(async (to, from, next) => {

  // 获取token
  const token = getToken()
  console.log('token:', token);
  // 存在token时
  if (token) {
    if (to.path === '/login') {
      // 若路由定向为登录或首页,则直接让用户跳转至合作方
      next({
        path: '/',
        replace: true
      })
    } else {
      //region 其他页面根据用户权限做路由拦截，并动态添加路由-------------------------------------------------------

      // const roles = store.getters.roles && store.getters.roles.length > 0
      // // 判断是否有角色
      // if (roles) {
      //   next() // 跳转
      // } else {
      //   try {
      //   // 去获取用户信息
      //   const role = await store.dispatch('user/getUserInfo')
      //  根据用户角色权限添加路由
      //   const accessRoutes = await store.dispatch('permit/generateRoutes', [])
      //  console.log(accessRoutes);
      //  // router.addRoutes(accessRoutes)
      //  // addRoutes未来会被vue-router废弃
      //  for (let accessRoute of accessRoutes) {
      //   router.addRoute(accessRoute)
      //  }
      // next({
      //   ...to,
      //   replace: true
      // })

      // } catch (error) {
      //   await store.dispatch('user/resetToken')
      //   next('/login')
      //   // NProgress.done()
      // }
      // }

      //endregion-----------------------------------------------------------------
      document.title = setTitle(to.meta.title) //设置浏览器标题<title></title>
      next()
    }

  } else {
    // 没有token
    console.log("无token!跳转登录页!")
    if (to.path !== '/login') {
      next({path: '/login', replace: true}) //跳转登录页
    } else {
      document.title = setTitle(to.meta.title) //设置浏览器标题<title></title>
      next() //是登录页就放行，否则会死循环
    }
  }
})

export default router
