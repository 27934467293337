<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      isShow: true,
    };
  },
  // provide inject 解决刷新闪现白屏问题
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    // 刷新
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}

#nprogress .bar {
  background: #a9a9a9 !important; //自定义颜色
}
</style>
