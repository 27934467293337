// import { getToken, setToken, removeToken } from '../../utils/cookie'
// import { login, getUserInfo, logOut } from '../../api/login'
// import { resetRouter } from '../../router/index'
//
// const state = {
//   token: getToken(), // token
//   name: '', // 姓名
//   ins: '校长', // 介绍
//   roles: [], // 角色
//   id: '', // id
//   schoolId: '001', // 学校id
//   schoolName: '',
//   timeSettingList: [], // 考评期数组
//   timeSetting: null, // 当前考评期
//   classCode: '', // 班级编号
//   gradeCode: '', // 年级编号
//   isAudit: false
// }
//
// const getters = {
//   token: state => state.token,
//   ins: state => state.ins,
//   name: state => state.name,
//   schoolId: state => state.schoolId,
//   roles: state => state.roles,
//   id: state => state.id,
//   classCode: state => state.classCode,
//   gradeCode: state => state.gradeCode,
//   timeSettingList: state => state.timeSettingList,
//   timeSetting: state => state.timeSetting,
//   isAudit: state => state.isAudit
// }
//
// const mutations = {
//   setToken (state, token) {
//     state.token = token
//   },
//   setName (state, name) {
//     state.name = name
//   },
//   setIns (state, ins) {
//     state.ins = ins
//   },
//   setRoles (state, roles) {
//     state.roles = roles
//   },
//   setId (state, id) {
//     state.id = id
//   },
//   setSchoolId (state, schoolId) {
//     state.schoolId = schoolId
//   },
//   setSchoolName (state, schoolName) {
//     state.schoolName = schoolName
//   },
//   setTimeSettingList (state, timeSettingList) {
//     state.timeSettingList = timeSettingList
//   },
//   setTimeSetting (state, timeSetting) {
//     state.timeSetting = timeSetting
//   },
//   setClassCode (state, classCode) {
//     state.classCode = classCode
//   },
//   setGradeCode (state, gradeCode) {
//     state.gradeCode = gradeCode
//   },
//   setIsAudit (state, isAudit) {
//     state.isAudit = isAudit
//   }
// }
//
// const actions = {
//   login ({ commit }, info) {
//     console.log('Vuex.store接收到的info', info)
//     const {
//       userName,
//       password
//     } = info
//     // 登录
//     return new Promise((resolve, reject) => {
//       login(userName, password).then(res => {
//         commit('setToken', res.token) //vuex保存用户token
//         setToken(res.token) //cookie设置用户token
//         resolve(res)
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   },
//
//   // 获取用户信息
//   getUserInfo ({
//     commit,
//     state
//   }) {
//     return new Promise((resolve, reject) => {
//       getUserInfo(state.token).then(res => {
//         const data = res.data
//         const {
//           realName,
//           role,
//           schoolCode,
//           schoolName,
//           userCode,
//           timeSettingList,
//           timeSetting,
//           classCode,
//           gradeCode,
//           isAudit
//         } = data
//         commit('setRoles', role)
//         commit('setName', realName)
//         commit('setIsAudit', isAudit)
//         commit('setSchoolId', schoolCode)
//         commit('setSchoolName', schoolName)
//         commit('setId', userCode)
//         commit('setTimeSettingList', timeSettingList)
//         commit('setTimeSetting', timeSetting)
//         commit('setClassCode', classCode)
//         commit('setGradeCode', gradeCode)
//         resolve(role)
//         // resolve('firstAdmin') // 测试时先赋超管
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   },
//
//   logOut ({
//     state,
//     commit
//   }) {
//     return new Promise((resolve, reject) => {
//       logOut(state.token).then(() => {
//         commit('setToken', '')
//         commit('setRoles', [])
//         removeToken().then()
//         resetRouter()
//         resolve()
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   },
//
//   resetToken ({ commit }) {
//     return new Promise((resolve, reject) => {
//       commit('setToken', '') // 重置token
//       commit('setRoles', []) // 重置roles
//       removeToken().then()
//       resolve()
//     })
//   }
// }
//
// export default {
//   namespaced: true,
//   state,
//   getters,
//   mutations,
//   actions
// }


import {getToken, removeToken, removeUserId, removeUserName, removeUserTel, removeUserType, setToken, setUserId, setUserName, setUserTel, setUserType} from "../../utils/cookie";
import {login, logOut} from "../../api/login";

export default {
  namespaced: true,
  state: {
    token: getToken(),
    userId: '', //账号id id=1是超管
    tel: "", //电话号码
    username: "", //用户名
    type: '', //账号类型。0：运营人员，1：管理员，默认 0
    creationTime: "", //创建时间
    updateTime: "", //更新时间
    isDel: '' //是否被删除。0：否，1：是
  },
  getters: {
    token: (state) => state.token,
    userId: (state) => state.userId,
    tel: (state) => state.tel,
    username: (state) => state.username,
    type: (state) => state.type,
    creationTime: (state) => state.creationTime,
    updateTime: (state) => state.updateTime,
    isDel: (state) => state.isDel
  },
  mutations: {
    updateToken(state, token) {
      state.token = token
    },
    updateUserId(state, id) {
      state.userId = id
    },

    updateTel(state, tel) {
      state.tel = tel
    },

    updateUserName(state, userName) {
      state.username = userName
    },

    updateType(state, type) {
      state.type = type
    },

    updateCreationTime(state, createTime) {
      state.creationTime = createTime
    },

    updateUpdateTime(state, updateTime) {
      state.updateTime = updateTime
    },

    updateIsDel(state, isDel) {
      state.isDel = isDel
    }
  },

  actions: {
    /**
     * 登录action
     * @param commit
     * @param loginForm
     * @returns {Promise<unknown>}
     */
    login({commit}, loginForm) {
      const {tel, password} = loginForm
      return new Promise((resolve, reject) => {
        login(tel, password).then((res) => {
          if (res && res.code === 1) { //登录成功后在vuex和cookie存储用户信息和token
            commit('updateToken', res.token)
            commit('updateUserId', res.data.id)
            commit('updateTel', res.data.tel)
            commit('updateUserName', res.data.username)
            commit('updateType', res.data.type)
            commit('updateCreationTime', res.data.creationTime)
            commit('updateUpdateTime', res.data.updateTime)
            commit('updateIsDel', res.data.isDel)
            setToken(res.token)
            setUserName(res.data.username)
            setUserId(res.data.id)
            setUserTel(res.data.tel)
            setUserType(res.data.type)
          }
          resolve(res)//无论登录成功失败直接把接口信息返回给dispatch
        }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * 退出登录action
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    logOut({state, commit}) {
      return new Promise((resolve, reject) => {
        logOut(state.token).then((res) => {
          //清空cookie和vuex的用户数据
          commit('updateToken', '')
          commit('updateUserId', '')
          commit('updateTel', '')
          commit('updateUserName', '')
          commit('updateType', '')
          commit('updateCreationTime', '')
          commit('updateUpdateTime', '')
          commit('updateIsDel', '')
          removeToken()
          removeUserName()
          removeUserId()
          removeUserTel()
          removeUserType()
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
