<template>
  <el-scrollbar :native="isNative">
    <el-menu :default-active="path" class="el-menu-vertical-demo" router>
      <template v-for="route in allRoutes">
        <!--onSide路由才需要渲染在侧栏菜单中-->
        <template v-if="route.meta.onSide">
          <el-menu-item
            :index="route.redirect"
            :route="route.redirect"
            v-if="route.children[0].meta.noChildren"
          >
            <i class="iconfont" :class="route.children[0].meta.icon"></i>
            <span slot="title">{{ route.children[0].meta.title }}</span>
          </el-menu-item>

          <!--存在子菜单-->
          <el-submenu :index="route.path" v-else>
            <template slot="title">
              <i class="iconfont" :class="route.meta.icon"></i>
              <span>{{ route.meta.title }}</span>
            </template>
            <el-menu-item
              v-for="(child,index) in route.children"
              :key="index"
              :index="resolvePath(route.path,child.path)"
              :route="resolvePath(route.path,child.path)"
            >{{ child.meta.title }}
            </el-menu-item>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'side',
  data () {
    return {
      isNative: true
    }
  },

  props: {
    // 导航栏是否折叠
    isCollapse: {
      default: false
    }
  },

  computed: {
    path () {
      return this.$route.path
    },
    // ...mapGetters(['permit/routes']), //为了在模板调用直接使用$store
    permittedRoutes () {
      return this.$store.getters['permit/routes']
    },
    allRoutes () {
      return this.$router.options.routes
    }
  },
  methods: {
    resolvePath (parentPath, childPath) {
      return `${parentPath}/${childPath}`
    }
  }
}
</script>

<style lang="scss" scoped>
.el-scrollbar {
  background-color: #fff;
  width: 100%;
  height: 100%;

  .el-menu {
    border-right: none;

    .el-menu-item,
    .menuparent {
      font-size: 14px;
    }

    .iconfont {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 16px;
    }
  }
}

.el-submenu__title .iconfont {
  margin-top: 1px;
}
</style>
