/**
 * @fileName    : request.js
 * @author      : llz
 * @date        : Created in 2022-04-12 15:56:02
 * @description : 封装axios
 * @version     :
 *
 * 鹿乃yyds!!!
 */

import axios from 'axios'
import qs from 'qs'

import { getToken } from './cookie'
import store from '../store'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 路径
  timeout: 10000 // 请求超时时间10s,
})

// 请求拦截器
service.interceptors.request.use((config) => {
  if (store.getters["user/token"]) {
    console.log("***vueX token***:",store.getters["user/token"]);
    console.log("***cookie token***:",getToken());
    config.headers['token'] = getToken() //请求头放入token
  }

  // console.log("config:", config)
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

  // if (store.getters.schoolId) {
  //   let data = qs.parse(config.data)
  //   if (config.method == 'post') {
  //     config.data = qs.stringify({
  //       ...data,
  //       schoolCode: store.getters.schoolId,
  //
  //     })
  //   } else if (config.method == 'get') {
  //     config.params = {
  //       ...config.params,
  //       schoolCode: store.getters.schoolId
  //     }
  //   }
  // }

  return config
}, (error) => {
  // 对请求错误进行处理
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {
  const res = response.data
  return res
  // 根据后台传来的code状态进行拦截
  // if (res.code != 0) {
  //   // Message({
  //   //     message: res.msg,
  //   //     type: 'error',
  //   // })
  //   return Promise.reject(new Error(res.msg))
  // } else {
  //   return res
  // }
}, (error) => {
  return Promise.reject(error)
})

export default service
